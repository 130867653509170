html {
    scroll-padding-top: 15vh;
}

.App {
    text-align: center;
}

.Header {
    border-bottom: 1px solid #f3f3f3;
    top: 0;
    position: fixed;
    background-color: #fff;
    width: 100vw;
    z-index: 99;
    height: 8vh;
}

.Footer {
    width: 100vw;
    background-color: #000a39;
    height: 50px;
    padding: 15px 0 10px 0;
    color: #fff;
    font-family: Montserrat;
    font-size: 16px;
}

.NavList {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    list-style: none;
    padding: 0 30px;
    margin-top: 30px;
}

.NavLink {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333 !important;
    font-family: Montserrat;
    font-size: 14px;
    letter-spacing: 1.4px;
    line-height: 23px;
    font-weight: 600;
    text-decoration: none;
    white-space: nowrap;
}

.Home {
    background-image: url('./img/home.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: calc(100vw-0px);
    height: 80vh;
    padding-top: 140px;
}

.Fintech {
    background-color: #000a39;
    width: calc(100vw-0px);
    height: fit-content;
    padding: 140px 0 50px 0;
}

.Fintech > .FlexRow {
    margin: 0 auto;
    width: 90vw;
}

.FintechList {
    list-style: none;
    width: fit-content;
    margin: 0 auto;
    text-align: left;
}

.FintechItems {
    opacity: 0.7;
    color: #fff;
    font-family: 'Open Sans';
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.1px;
    margin-bottom: 5px;
    padding-left: 20px;
    position: relative;
}

.FintechItems::before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    background-color: #5c6ac4;
    position: absolute;
    left: 0;
    top: calc(50% - 3px);
}

.BannerTitle {
    font: 600 51px/61px Montserrat-Bold;
}

.BannerSubtitle {
    color: #666;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
    max-width: 700px;
    margin: 0 auto 40px;
}

.HomeButtonContainer {
    max-width: 800px;
    margin: 0 auto;
}

.Solutions {
    background-color: #000a39;
    padding: 50px 0;
    margin: 0 auto;
}

.SolutionsLists {
    width: 60vw;
    margin: 0 auto;
}

.SolutionsList {
    list-style: none;
    width: fit-content;
    margin: 0 auto;
    text-align: left;
}

.SolutionsItems {
    opacity: 0.7;
    color: #fff;
    font-family: 'Open Sans';
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.1px;
    margin-bottom: 5px;
    padding-left: 20px;
    position: relative;
}

.SolutionsItems::before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    background-color: #5c6ac4;
    position: absolute;
    left: 0;
    top: calc(50% - 3px);
}

.About {
    padding: 50px 0;
    background-color: #000a39;
}

.AboutParagraph {
    font-size: 20px;
    max-width: 50vw;
    margin: 0 auto;
    text-align: left;
    letter-spacing: 0.05em;
    line-height: 200% !important;
    color: #fff;
    opacity: 0.7;
}

.Founder {
    padding: 50px 0;
    max-width: 70vw;
    margin: 0 auto;
}

.FounderTitle {
    font-family: Montserrat;
    font-size: 36px;
    font-weight: 600;
}

.FounderSubtitle {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 500;
}

.FounderParagraph {
    font-size: 20px;
    max-width: 50vw;
    margin: 0 auto;
    text-align: left;
    letter-spacing: 0.05em;
    line-height: 200% !important;
}

.FounderLinks {
    padding: 10px 0;
    margin: 0 auto;
}

.Testimonials {
    padding: 50px 0;
}

.TestimonialCards {
    margin: 0 auto;
    max-width: 50vw;
    height: auto !important;
}

.Portfolio {
    margin: 0 auto;
    width: 70vw;
    text-align: left;
    padding: 50px 0;
}

.PortfolioStory {
    max-width: 80%;
    padding-top: 20px;
    margin: 0 auto;
}

.Hovering {
    background-color: #000a39;
    background-size: cover;
    color: #fff;
    font-family: Montserrat;
    padding: 20px;
}

.Hovering h1 {
    font-size: 23px !important;
}

.Hovering p {
    font-size: 16px !important;
    letter-spacing: 1px;
    line-height: 2;
}

.Contact {
    background-color: #000a39;
    padding: 50px 0;
    width: 100vw;
    margin: 0 auto;
}

.ContactForm {
    width: 40vw;
    margin: 0 auto;
    text-align: center;
}

.ContactLabel {
    font-family: Montserrat;
    font-size: 14px !important;
    padding-left: 5px;
    letter-spacing: 1px;
}

.ContactField {
    margin: 0 auto !important;
    padding: 10px;
}

.ContactFormMessage {
    width: 30vw;
    margin: 0 auto !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.ContactSubmit {
    margin-top: 20px !important;
}

.HeaderText {
    text-align: center;
    color: #fff;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 400;
    line-height: 33px;
    margin-bottom: 50px;
}

.SubheaderText {
    text-align: center;
    color: #fff;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    line-height: 33px;
}

.ActionButton {
    margin: 45px auto 0;
    display: block;
    width: 250px;
    height: 48px;
    border-radius: 3px;
    background-color: #fb8b8a;
    color: #fff !important;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 48px;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
    text-decoration: none;
}

.Primary {
    color: #000a39;
}

.Accent {
    color: #fb8b8a;
}

.AccentBackground {
    background-color: #fb8b8a !important;
}

.White {
    color: #fff !important;
}

.Required {
    color: red;
}

.Left {
    text-align: left;
}

.FlexRow {
    display: flex;
    flex-wrap: wrap;
}

.FlexColumn {
    flex: 1;
}

/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 36px;
    top: 18px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #000a39;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #fb8b8a;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: #000a39;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

/* Individual item */
.bm-item {
    display: inline-block;
    padding: 20px;
    font-family: 'Montserrat';
    font-size: 23px;
    color: #fff;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    width: auto !important;
}

@font-face {
    font-family: 'Montserrat-Bold';
    src: url(./fonts/Montserrat-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    src: url(./fonts/Montserrat-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Open Sans';
    src: url(./fonts/OpenSans-Medium.ttf) format('truetype');
}

@media only screen and (max-width: 600px) {
    .Header {
        height: 10vh;
        padding-left: 36px;
    }

    .HeaderText {
        max-width: 80vw;
        margin: 0 auto;
        padding-bottom: 20px;
    }

    .Home {
        background-image: none;
        overflow-wrap: break-word;
        height: 100vh;
    }

    .Fintech {
        height: fit-content;
        overflow-wrap: break-word;
    }

    .Fintech > .FlexRow > .FlexColumn:nth-child(2) {
        padding-top: 20px;
    }

    .BannerTitle {
        font: 600 46px/56px Montserrat-Bold;
    }

    .BannerSubtitle {
        max-width: calc(100vw - 50px);
    }

    .SolutionsLists {
        width: 85vw;
    }

    .AboutParagraph {
        max-width: 70vw;
    }

    .FounderParagraph {
        max-width: 70vw;
        text-align: center;
    }

    .FounderLinks {
        width: 40vw;
    }

    .TestimonialCards {
        max-width: 80vw;
    }

    .TestimonialCardsGroup {
        margin: 0 auto !important;
    }

    .Portfolio {
        width: 80vw;
    }

    .ContactForm {
        width: 90vw;
    }
}

@media only screen and (max-width: 1600px) {
    .TestimonialCards {
        max-width: 70vw;
    }

    .NavFlex {
        flex: 1.8;
    }
}
